<template>
  <div :class="{ contain: true, mobile: isMobile }">
    <div class="content d-flex flex-column flex-lg-row mb-16">
      <div class="left ml-lg-8 mr-lg-5">
        <div class="PromoImage">
          <img src="@/assets/promoImage/Uptick_Halloween.jpg" alt="" />
        </div>
        <div class="timer d-flex flex-lg-row" v-if="isTimeOut">
          <div class="days">
              <div class="top">
                 <span class="mr-1">{{d1}}</span>
                 <span class="mr-2">{{d2}}</span>
              </div>
              <div class="bom">
                  DAY
              </div>
          </div>
          <div class="mao">:</div> 
          <div class="hours">
              <div class="top">
                 <span class="mr-1 ml-2">{{h1}}</span>
                 <span class="mr-2">{{h2}}</span>
              </div>
              <div class="bom">
                  HR
              </div>
          </div> 
          <div class="mao">:</div> 
          <div class="minutes">
              <div class="top">
                 <span class="ml-2 mr-1">{{m1}}</span>
                 <span class="mr-2">{{m2}}</span>
              </div>
              <div class="bom">
                  MIN
              </div>
          </div> 
          <div class="mao">:</div> 
          <div class="seconds">
              <div class="top">
                 <span class="ml-2 mr-1">{{s1}}</span>
                 <span class="mr-2">{{s2}}</span>
              </div>
              <div class="bom">
                  SEC
              </div>
          </div> 
           
        </div>
        <!-- <div v-else class="endDesc">
            <span  v-if="!isStart"> In the statistics, please be patient.
        </span>
        </div> -->
		
        <div class="btn mt-6 ">
         <!-- 未登录  -->
          <!-- <button class="notClaim " v-if="!this.$store.state.did" >{{ $t("admin_activity_btn_buynft") }}</button> -->
	    	<!-- 未到领取时间 --> 
	  	<!-- <button class="notClaim " v-else-if="!isStart" >{{ $t("admin_activity_btn_nostart") }}</button> -->
        <!-- 活动开始，领取 -->
         <!-- <button class="claim " v-else-if=" isStart && isTimeOut" @click="onClicked">{{ $t("admin_activity_btn_buynft") }}</button>	 -->
        <!-- 已结束-->
			  <!-- <button class="notClaim" v-else-if="!isTimeOut">{{ $t("token_state_end") }}</button>  -->
          <button class="claim "  @click="onClicked">{{ $t("admin_activity_btn_buynft") }}</button>	
        </div>
      </div>
      <div class="right mt-lg-0 mt-9">
        <div class="title mt-2 mb-lg-6">
          <span>Uptick Halloween Campaign</span>
        </div>
        <div class="desc mb-lg-6">
          <p>
          During the Halloween period,  Halloween limited NFTs will be listed on Uptick on IRISnet and Uptick on Loopring, respectively. Each will be limited to 200 pieces in total.
          </p>
           <p>
           The Holders of these limited edition Halloween NFTs will be granted different privileges and benefits within the Uptick Ecosystem, including being added to the whitelist for the upcoming Uptick mainnet airdrop. 
          </p>
           <p>
           Participants can also get a  Free Halloween Badge, which contains DOUBLE airdrop reward if you partake in the promotional task.
          </p>
        </div>
        <div class="claimtime mb-lg-6">
          <div> <span>Campaign Time：</span>  2022.10.28 18:00 - 2022.11.2 12:00 (UTC+8) </div>
          <div> <span>Claim Time：</span> 2022.11.3 18:00 (UTC+8)</div>
        </div>
        <div class="prize">
          <div>
            <div class="rules mb-3">How to participate in the Halloween Campaign?</div>  
             <div class="titles">1. Buy  <a target="blank" href="https://irisnet.upticknft.com/folder/UptickHalloween2022">Uptick Halloween NFT</a></div>
            <div class="titles">2. Follow @uptickproject & @upticknft on Twitter, join the Uptick <a target="blank" href="https://t.me/uptickproject">Telegram</a> & <a target="blank" href="https://discord.com/invite/teqX78VZUV" >Discord</a> </div>
            <div class="titles">3. Tweet us a pic of the Halloween NFT you collected and tag 2 of your friends</div>
             <div class="titles">4. Fill in the  <a target="blank" href="https://forms.gle/3ZJtTsMVZVdmpSPS9">registration form</a></div>
             <br/>
              <div class="titles">Please fill out the form in detail and we will evaluate it. </div>
               <div class="titles">After the event, we will uniformly airdrop free NFT for eligible participants. Please be patient! </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
let drawAtomStartTimeStamp;
export default {
  data() {
    return {
      d1:'',
      d2:'',
      h1:'',
      h2:'',
      m1:'',
      m2:'',
      s1:'',
      s2:'',
      isPhotographyEnd:false,
      isStart:false,
      isTimeOut:true,
    };
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
   async mounted() {
     this.Time();
     var timestamp = new Date().valueOf() 
      let nowTimeStamp = timestamp;
      // ATOM开始领取时间 // 1666951200000
       drawAtomStartTimeStamp = 1666951200000
        if (nowTimeStamp > drawAtomStartTimeStamp) {
        this.isStart = true
      } else {
          this.isStart = false
    
      }
    
      
   },
  methods: {
    
    toContest() {
      this.$router.push({ name: "Competition" });
    },
    async onClicked(){
      // this.isClicked = true
      window.open('https://irisnet.upticknft.com/folder/UptickHalloween2022','_blank');

       
    },
    getTime() {
      // Atom倒计时结束时间1667361600000
      let photographyEndTimeStamp = 1667361600000;//
       this.now = new Date().valueOf()    
        this.end = photographyEndTimeStamp;
          if (this.now < this.end) {
            this.seconds = (this.end - this.now) / 1000;
			this.isTimeOut=true;
          }else{ 
           this.isTimeOut=false;
       
           //  if(this.now > drawAtomStartTimeStamp && this.now<drawAtomEndTimeStamp ){
           //    this.isDraw = true
           //  }else{
           
           //     this.isDraw = false
           //  }
          }
     },
      // 天 时 分 秒 格式化函数
      countDown() {
        let d = parseInt(this.seconds / (24 * 60 * 60));
        let day =  d < 10 ? "0" + d :  d;
        this.d1 = String(day).split("")[0]
        this.d2 = String(day).split("")[1]
        //let h = parseInt(((this.seconds / (60 * 60)) % 24) + d * 24);
        let h = parseInt((this.seconds / (60 * 60)) % 24);
        let hour = h < 10 ? "0" + h : h;
        this.h1 = String(hour).split("")[0]
        this.h2 = String(hour).split("")[1]
        let m = parseInt((this.seconds / 60) % 60);
        let minutes = m < 10 ? "0" + m : m;
        this.m1 = String(minutes).split('')[0];
        this.m2 = String(minutes).split('')[1];
        let s = parseInt(this.seconds % 60);
        let second = s < 10 ? "0" + s : s;
        this.s1 = String(second).split("")[0]
        this.s2 = String(second).split("")[1]
      },
      //定时器没过1秒参数减1
      Time() {
        setInterval(() => {
          if (this.seconds > 0) {
            this.seconds -= 1;
            this.countDown();
          } else {
            this.getTime();
          }
        }, 1000);
      },
  },
};
</script>

<style lang="scss" scoped>
.contain {
  .content {
    margin-top: 50px;
    .left {
      width: 560px;
      // height: 550px;
      img {
        width: 560px;
        height: 550px;
        display: block;
        object-fit: contain;
        cursor: pointer;
      }
      .timer{
        // max-width:305px;
        // margin:17px auto 21px;
	    	justify-content: center;
		        margin-top:17px;
        .mao{
          
          font-family:Helvetica;
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 30px;
          letter-spacing: 0px;
          color: #270645;
        }
        .bom{
          font-family:Helvetica;
          font-size: 11px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 20px;
          letter-spacing: 0px;
          color: #766983;
          text-align:center;
          margin-top:7px;
        }
        span{
          width:27px;
          height:36px;
          display:inline-block;
          text-align:center;
          line-height:36px;
          background-color: #270645;
	    border-radius: 5px;
          font-family:Helvetica;
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #ffffff;
        }
       
      }
      .endDesc{
       
        margin-top:20px;
         color: red;
		 text-align: center;
      }
      .btn {
       
        border-radius: 25px;
        text-align: center;
        margin: 0 auto;
        .notClaim{
        


                 width: 276px;
        height: 51px;
           border-radius: 25px;
            background-color: #766983;
          font-family:Helvetica;
          font-size: 23px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 51px;
          letter-spacing: 0px;
          color: #ffffff;

        }

        .claim { 
          width: 276px;
        height: 51px;
          border-radius: 25px;
        background-image: linear-gradient(#270645, #270645),
          linear-gradient(
            90deg,
            #d300fd 0%,
            #a439fe 26%,
            #7471ff 52%,
            #00fdcf 100%
          ),
          linear-gradient(#270645, #270645);
        background-blend-mode: normal, normal, normal;
       
          font-family:Helvetica;
          font-size: 23px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 51px;
          letter-spacing: 0px;
          color: #ffffff;
           
        }
      }
    }
    .right {
      width: 588px;
      // height: 613px;
      .title {
        span {
           font-family: Helvetica;
          font-size: 45px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 45px;
          letter-spacing: 0px;
          color: #270645;
        }
      }
      .desc {
        .celebrate {
            width: 620px;
          font-family:Helvetica;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 23px;
          letter-spacing: 0px;
          color: #270645;
        }
      }
	  .desc {
	    p {
	      max-width: 567px;
	      font-family:Helvetica;
	      font-size: 15px;
	      font-weight: bold;
	      font-stretch: normal;
	      line-height: 23px;
	      letter-spacing: 0px;
	      color: #270645;
	    }
	  }
      .claimtime {
          span{
            font-family: Helvetica;
            font-size: 15px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
          }
        div {
          font-family:Helvetica;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 18px;
          letter-spacing: 0px;
          color: #270645;
        }
      }
      .prize {
       .rules{
           font-family: Helvetica;
            font-size: 13px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
       }
       .titles{
           font-family: Helvetica;
            font-size: 13px;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;

       }
      }
    }
  }
  &.mobile {
    .content {
      margin-top: 30px;
      .left {
        max-width: 100%;
        min-height: 437px;
        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: contain;
          cursor: pointer;
        }
        .timer{
          // width:90%;
          // margin-left:14%;
          	justify-content: center;
		        margin-top:17px;
          span{
            width:20px;
            height:30px;
            font-size:15px;
            line-height:30px;
          }
        }
        .endDesc{
        width: 340px;
        margin-left: 7px;
        margin-top:20px;
        color: red;
      }
        .btn {
          width: 80%;
          .claim {
            width: 80%;
          }
        }
      }
      .right {
        max-width: 100%;
        min-height: 613px;
        .title {
          margin-bottom: 34px;
          span {
            font-size: 25px;
            line-height: 40px;
          }
        }
        .desc {
          // max-width:576px;
          margin-bottom: 28px;
        }
        .prize{
           min-height: 420px;
        }
        .claimtime {
          margin-bottom: 34px;
        }
      }
    }
  }
}
</style>
